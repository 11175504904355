import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";

const FormRow = ({children, rowName}: FormRowProps) => {
    return (
        <div className={"mc-form-row"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h6 className="mb-0">{rowName}</h6>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>{children}</div>
            </div>
        </div>
    );
};

export default FormRow;

interface FormRowProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnHeaderClassName?: string;
    columnDetailClassName?: string;
    formRowClassName?: string;
}
