import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import EditReportFormParent from "./Components/EditReportFormParent";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import {useUserData} from "../../Hooks/useUserData";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getReportFromService} from "../../../store/reports/actions/ReportActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ReportDataServiceWrapper = WithServiceState(EditReportFormParent);

const EditReport = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const reportStore = useSelector((state: RootStore) => state.report);
    const user = useUserData();
    const history = useHistory();

    useEffect(() => {
        if (user.username.length === 0) return;
        const navItems: NavigationItem[] = [
            {
                name: routeNames.reportList.name,
                path: routeNames.reportList.path
            },
            {
                name: routeNames.reportEdit.name,
                path: history.location.pathname
            }
        ];
        dispatch(setNavigationItems(navItems));
        dispatch(getReportFromService(+params.id));
    }, [user]);

    return (
        <React.Fragment>
            <TopBar titleText="Edit Report" />
            <div className="page-container">
                <ReportDataServiceWrapper
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...reportStore}
                />
            </div>
        </React.Fragment>
    );
};

export default EditReport;
