import React from "react";
import {WithServiceState} from "store-fetch-wrappers";
import ReportListDesktopView from "./Components/Desktop/ReportListDesktopView";
import {useReportList} from "./Hooks/useReportList";
import {PlatformOS, usePlatform} from "../../Hooks/usePlatform";
import ReportListMobileView from "./Components/Mobile/ReportListMobileView";
import AuthAmI from "../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../api/staff";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const DesktopServiceWrapper = WithServiceState(ReportListDesktopView);
const MobileServiceWrapper = WithServiceState(ReportListMobileView);

const ReportList = () => {
    const {reportListStore} = useReportList();
    const platform = usePlatform();

    return (
        <React.Fragment>
            <TopBar titleText="Incident Reports" />
            <div className="page-container">
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <DesktopServiceWrapper
                        loaderWheelType={"three-ring"}
                        loaderType={"overlay"}
                        showLoadingText={false}
                        placeholderBlockCount={1}
                        {...reportListStore}
                    />
                </AuthAmI>
                <AuthAmI accessLevels={[StaffAccessLevel.DutyManager, StaffAccessLevel.Staff]}>
                    {platform === PlatformOS.Desktop ? (
                        <DesktopServiceWrapper
                            loaderWheelType={"three-ring"}
                            loaderType={"overlay"}
                            showLoadingText={false}
                            placeholderBlockCount={1}
                            {...reportListStore}
                        />
                    ) : (
                        <MobileServiceWrapper
                            loaderWheelType={"three-ring"}
                            loaderType={"overlay"}
                            showLoadingText={false}
                            placeholderBlockCount={1}
                            {...reportListStore}
                        />
                    )}
                </AuthAmI>
            </div>
        </React.Fragment>
    );
};

export default ReportList;
